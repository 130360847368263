import { UPDATE_ZONE, DELETE_ZONE } from './../graphql/mutations/mutations';
import { Zone, CreateZoneInput, UpdateZoneInput, successPayload, GetZoneInput } from './../graphql/types/types';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { PaginationOptions } from '../graphql/types/types';
import { ZONE, ZONES } from '../graphql/queries/queries';
import { CREATE_ZONE } from '../graphql/mutations/mutations';

@Injectable({
  providedIn: 'root'
})
export class ZoneService {

  constructor(private apollo: Apollo) { }

  listAllZones(Obj: PaginationOptions) {
    return this.apollo.query<[Zone]>({
      query: ZONES,
      variables: { paginationOptions: Obj }
    })
  }


  listZone(Obj: GetZoneInput) {
    return this.apollo.query<[Zone]>({
      query: ZONE,
      variables: { zoneInput: Obj }
    })
  }

  addZone(Obj: CreateZoneInput) {
    return this.apollo.mutate<Zone>({
      mutation: CREATE_ZONE,
      variables: { zoneInput: Obj }
    })
  }

  updateZone(Obj: UpdateZoneInput) {
    return this.apollo.mutate<Zone>({
      mutation: UPDATE_ZONE,
      variables: { zoneInput: Obj }
    })
  }

  deleteZone(Obj: GetZoneInput) {
    return this.apollo.mutate<successPayload>({
      mutation: DELETE_ZONE,
      variables: { zoneInput: Obj }
    })
  }

}
