import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CreateSetCategoryInput, PaginationOptions, SetCategory, subCategory, successPayload, UpdateSetCategoryInput } from 'app/@resources/graphql/types/types';
import { CREATE_SET_CATEGORY, DELETE_SET_CATEGORY, UPDATE_SET_CATEGORY } from '../graphql/mutations/mutations';
import { SET_CATEGORIES, SET_CATEGORY, SUB_CATEGORY } from '../graphql/queries/queries';

@Injectable({
  providedIn: 'root'
})
export class SetCategoryService {

  constructor(private apollo: Apollo) { }

  listAllSetCategories(Obj: PaginationOptions) {
    return this.apollo.query<[SetCategory]>({
      query: SET_CATEGORIES,
      variables: { paginationOptions: Obj }
    })
  }

  listSubCategoriesById(subCategoryId:string) {
    return this.apollo.query<[subCategory]>({
      query: SUB_CATEGORY,
      variables: { subCategoryId: subCategoryId }
    })
  }

  listSetCategory(setCategoryId: string) {
    return this.apollo.query<[SetCategory]>({
      query: SET_CATEGORY,
      variables: { setCategoryId: setCategoryId }
    })
  }

  addSetCategory(Obj: CreateSetCategoryInput) {
    return this.apollo.mutate<[SetCategory]>({
      mutation: CREATE_SET_CATEGORY,
      variables: { createSetCategoryInput : Obj },
      context:{ useMultipart: true }
    })
  }
  
  updateSetCategory(Obj: UpdateSetCategoryInput, setCategoryId: string){
    return this.apollo.mutate<[SetCategory]>({
      mutation: UPDATE_SET_CATEGORY,
      variables: { updateSetCategoryInput: Obj, setCategoryId: setCategoryId },
      context:{ useMultipart: true }
    })
  }

  deleteSetCategory(setCategoryId: string){
    return this.apollo.mutate<[successPayload]>({
      mutation: DELETE_SET_CATEGORY,
      variables: { setCategoryId: setCategoryId }
    })
  }

}
