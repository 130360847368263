import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CreateSkinColorInput, PaginationOptions, SkinColors, successPayload, UpdateSkinColorInput } from '../graphql/types/types';
import { SKIN_COLOR, SKIN_COLORS } from '../graphql/queries/queries';
import { CREATE_SKIN_COLOR, UPDATE_SKIN_COLOR, DELETE_SKIN_COLOR } from '../graphql/mutations/mutations';

@Injectable({
  providedIn: 'root'
})
export class SkinColorsService {

  constructor(private apollo: Apollo) { }

  listAllSkinColors(Obj: PaginationOptions) {
    return this.apollo.query<[SkinColors]>({
      query: SKIN_COLORS,
      variables: { paginationOptions: Obj }
    })
  }

  listSkinColor(skinColorId:string) {
    return this.apollo.query<SkinColors>({
      query: SKIN_COLOR,
      variables: { skinColorId: skinColorId }
    })
  }

  addSkinColor(Obj:CreateSkinColorInput){
    return this.apollo.mutate<SkinColors>({
      mutation:CREATE_SKIN_COLOR,
      variables:{ createSkinColorInput: Obj },
      context:{ useMultipart: true }
    })
  }

  updateSkinColor(Obj:UpdateSkinColorInput,skinColorId:string){
    return this.apollo.mutate<SkinColors>({
      mutation:UPDATE_SKIN_COLOR,
      variables:{ updateSkinColorInput: Obj, skinColorId:skinColorId},
      context:{ useMultipart: true }
    })
  }

  deleteSkinColor(skinColorId:string){
    return this.apollo.mutate<successPayload>({
      mutation:DELETE_SKIN_COLOR,
      variables:{ skinColorId: skinColorId }
    })
  }
}
