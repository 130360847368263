import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CREATE_WEIGHT_RANGE, DELETE_WEIGHT_RANGE, UPDATE_WEIGHT_RANGE } from '../graphql/mutations/mutations';
import { WEIGHT_RANGE, WEIGHT_RANGES } from '../graphql/queries/queries';
import { CreateWeightRangeInput, PaginationOptions, successPayload, UpdateWeightRangeInput, WeightRanges } from '../graphql/types/types';

@Injectable({
  providedIn: 'root'
})
export class WeightRangesService {

  constructor(private apollo: Apollo) { }

  public listAllWeightRange(Obj: PaginationOptions) {
    return this.apollo.query<[WeightRanges]>({
      query: WEIGHT_RANGES,
      variables: { paginationOptions: Obj }
    })
  }

  listWeightRange(weightRangeId:string) {
    return this.apollo.query<WeightRanges>({
      query: WEIGHT_RANGE,
      variables: { weightRangeId: weightRangeId }
    })
  }

  addWeightRange(Obj:CreateWeightRangeInput){
    return this.apollo.mutate<WeightRanges>({
      mutation:CREATE_WEIGHT_RANGE,
      variables:{ createWeightRangeInput: Obj },
      context:{ useMultipart: true }
    })
  }

  updateWeightRange(Obj:UpdateWeightRangeInput,weightRangeId:string){
    return this.apollo.mutate<WeightRanges>({
      mutation:UPDATE_WEIGHT_RANGE,
      variables:{ updateWeightRangeInput: Obj, weightRangeId:weightRangeId},
      context:{ useMultipart: true }
    })
  }

  public deleteWeightRange(weightRangeId:string){
    return this.apollo.mutate<successPayload>({
      mutation:DELETE_WEIGHT_RANGE,
      variables:{ weightRangeId: weightRangeId }
    })
  }
}
