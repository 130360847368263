import { CREATE_CONSTRUCTION, CREATE_VARIANT_CONSTRUCTION, DELETE_CONSTRUCTION, UPDATE_CONSTRUCTION, DELETE_VARIANT_CONSTRUCTION } from './../graphql/mutations/mutations';
import { CONSTRUCTION, CONSTRUCTIONS } from './../graphql/queries/queries';
import { Construction, CreateConstructionInput, CreateVariantConstructionInput, successPayload, UpdateConstructionInput } from './../graphql/types/types';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { PaginationOptions } from '../graphql/types/types';

@Injectable({
  providedIn: 'root'
})
export class ContstructionService {

  constructor(private apollo: Apollo) { }

	listAllConstructions(Obj: PaginationOptions) {
		return this.apollo.query<[Construction]>({
		  query: CONSTRUCTIONS,
		  variables: { paginationOptions: Obj }
		})
	  }
	
	
	  listConstruction(constructionId: string) {
		return this.apollo.query<Construction>({
		  query: CONSTRUCTION,
		  variables: { constructionId: constructionId }
		})
	  }
	  
	  addVariantConstruction(Obj:CreateVariantConstructionInput,variantId:string){
		return this.apollo.mutate<Construction>({
			mutation:CREATE_VARIANT_CONSTRUCTION,
			variables:{ createVariantConstructionInput: Obj,variantId: variantId }
		  })
	  }

	  deleteVariantConstruction(constructionId:string,variantId:string){
		return this.apollo.mutate<Construction>({
			mutation:DELETE_VARIANT_CONSTRUCTION,
			variables:{ constructionId: constructionId,variantId: variantId }
		  })
	  }

	  addConstruction(Obj:CreateConstructionInput){
		return this.apollo.mutate<Construction>({
		  mutation:CREATE_CONSTRUCTION,
		  variables:{ constructionInput: Obj }
		})
	  }
	
	  updateConstruction(Obj:UpdateConstructionInput,constructionId:string){
		return this.apollo.mutate<Construction>({
		  mutation:UPDATE_CONSTRUCTION,
		  variables:{ constructionInput: Obj, constructionId: constructionId }
		})
	  }
	
	  deleteConstruction(constructionId:string){
		return this.apollo.mutate<successPayload>({
		  mutation:DELETE_CONSTRUCTION,
		  variables:{ constructionId: constructionId }
		})
	  }
}
