import { CreateAttributeInput } from 'app/@resources/graphql/types/types';
import { AttributeValue, CreateVariantAttributeInput, AttributeTypeEnum } from './../graphql/types/types';
import { CREATE_ATTRIBUTE, UPDATE_ATTRIBUTE, DELETE_ATTRIBUTE, CREATE_VARIANT_ATTRIBUTE, DELETE_VARIANT_ATTRIBUTE } from './../graphql/mutations/mutations';
import { ATTRIBUTE, ATTRIBUTES, ATTRIBUTE_VALUES_BY_ATTRIBUTE_ID, ATTRIBUTES_BY_SUB_CATEGORY, ATTRIBUTE_TYPE_ENUM } from './../graphql/queries/queries';
import { Attribute, Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { PaginationOptions, successPayload, UpdateAttributeInput } from '../graphql/types/types';

@Injectable({
	providedIn: 'root'
})
export class AttributeService {

	constructor(private apollo: Apollo) { }

	listAllAttributes(Obj: PaginationOptions) {
		return this.apollo.query<[Attribute]>({
			query: ATTRIBUTES,
			variables: { paginationOptions: Obj }
		})
	}

	attributeTypes(){
		return this.apollo.query<[AttributeTypeEnum]>({
			query: ATTRIBUTE_TYPE_ENUM
		})
	}

	listAttributesBySubCategoryId(subCategoryId: PaginationOptions) {
		return this.apollo.query<[Attribute]>({
			query: ATTRIBUTES_BY_SUB_CATEGORY,
			variables: { subCategoryId: subCategoryId }
		})
	}

	listAttribute(attributeId: string) {
		return this.apollo.query<Attribute>({
			query: ATTRIBUTE,
			variables: { attributeId: attributeId }
		})
	}

	listAttributeValuesById(attributeId: string) {
		return this.apollo.query<AttributeValue>({
			query: ATTRIBUTE_VALUES_BY_ATTRIBUTE_ID,
			variables: { attributeId: attributeId }
		})
	}

	addVariantAttribute(variantId: string,obj: CreateVariantAttributeInput) {
		return this.apollo.mutate<Attribute>({
			mutation: CREATE_VARIANT_ATTRIBUTE,
			variables: { createAttributeInput: obj, variantId: variantId }
		})
	}

	deleteVariantAttribute(attributeId: string, variantId: string) {
		return this.apollo.mutate<successPayload>({
			mutation: DELETE_VARIANT_ATTRIBUTE,
			variables: { attributeId: attributeId, variantId: variantId }
		})
	}
	addAttribute(Obj: CreateAttributeInput) {
		return this.apollo.mutate<Attribute>({
			mutation: CREATE_ATTRIBUTE,
			variables: { attributeInput: Obj }
		})
	}

	updateAttribute(Obj: UpdateAttributeInput, attributeId: string) {
		return this.apollo.mutate<Attribute>({
			mutation: UPDATE_ATTRIBUTE,
			variables: { attributeInput: Obj, attributeId: attributeId }
		})
	}

	deleteAttribute(attributeId: string) {
		return this.apollo.mutate<successPayload>({
			mutation: DELETE_ATTRIBUTE,
			variables: { attributeId: attributeId }
		})
	}

}
