import { CROSS_SELLING_VARIANTS, VARIANTS_COUNT, VARIANT_BY_AVAILABILITY, VARIANT_SEARCH } from './../graphql/queries/queries';
import { CountPayload, VariantAvailabilityInput } from './../graphql/types/types';
import { CREATE_VARIANT, UPDATE_VARIANT, DELETE_VARIANT } from './../graphql/mutations/mutations';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { VARIANT, VARIANTS } from '../graphql/queries/queries';
import { PaginationOptions, Variant } from '../graphql/types/types';

@Injectable({
  providedIn: 'root'
})
export class VariantService {
  constructor(private apollo: Apollo) { }

  listAllVariants(Obj: PaginationOptions) {
    return this.apollo.query<[Variant]>({
      query: VARIANTS,
      variables: { paginationOptions: Obj }
    })
  }
  
  variantSearch(name:string){
    return this.apollo.query<[Variant]>({
      query: VARIANT_SEARCH,
      variables: { name:  name }
    })
    
  }
  
  variantsByAvailability(Obj1: VariantAvailabilityInput,Obj2:PaginationOptions) {
    return this.apollo.query<[Variant]>({
      query: VARIANT_BY_AVAILABILITY,
      variables: { variantAvailabilityInput: Obj1,paginationOptions: Obj2 }
    })
  }

  listAllCrossSellingVariants(Obj: PaginationOptions) {
    return this.apollo.query<[Variant]>({
      query: CROSS_SELLING_VARIANTS,
      variables: { paginationOptions: Obj }
    })
  }
  
  variantsCount() {
    return this.apollo.query<CountPayload>({
      query: VARIANTS_COUNT
    })
  }

  addVariant(obj) {
    
    return this.apollo.mutate<Variant>({
      mutation: CREATE_VARIANT,
      variables: { createVariantInput: obj },
      context:{ useMultipart: true }
    })
  }

  updateVariant(obj,variantId){
    return this.apollo.mutate<Variant>({
      mutation: UPDATE_VARIANT,
      variables: { updateVariantInput:obj,variantId: variantId },
      context:{ useMultipart: true }
    })
  }

  listVariant(variantId:string) {
    return this.apollo.query<Variant>({
      query: VARIANT,
      variables: { variantId: variantId }
    })
  }

  deleteVariant(variantId:string) {
    return this.apollo.mutate<Variant>({
      mutation: DELETE_VARIANT,
      variables: { variantId: variantId }
    })
  }
}
