import { CREATE_BUYER, UPDATE_BUYER, DELETE_BUYER, RESET_PASSWORD_BUYER, REACTIVATE_BUYER, CREATE_ADDRESS, UPDATE_ADDRESS, DELETE_ADDRESS, RESET_EMAIL_REQUEST, FOLLOW_BUYER } from './../graphql/mutations/mutations';
import { ADDRESS_BY_BUYER, ADDRESS_TYPES, AREAS_BY_CITY, BUYER, ADDRESS_BY_ZONE, BUYERS_COUNT, BUYERS_SEARCH } from './../graphql/queries/queries';
import { Buyer, CreateBuyerInput, successPayload, UpdateBuyerDetailsInput, ActivationInput, ResetUserPasswordInput, Area, Address, CreateAddressInput, UpdateAddressInput, AddressTypes, AddressZoneInput, CountPayload, BuyerSearchInput, FollowBuyerInput } from './../graphql/types/types';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { PaginationOptions } from '../graphql/types/types';
import { BUYERS } from '../graphql/queries/queries';

@Injectable({
	providedIn: 'root'
})
export class BuyerService {

	constructor(private apollo: Apollo) { }

	listAllBuyers(Obj: PaginationOptions) {
		return this.apollo.query<[Buyer]>({
			query: BUYERS,
			variables: { paginationOptions: Obj }
		})
	}
	listBuyer(buyerId: string) {
		return this.apollo.query<Buyer>({
			query: BUYER,
			variables: { buyerId: buyerId }
		})
	}

	buyersCount(){
		return this.apollo.query<CountPayload>({
			query: BUYERS_COUNT
		})
	}

	buyerSearch(Obj:BuyerSearchInput){
		return this.apollo.query<[Buyer]>({
			query:BUYERS_SEARCH,
			variables:{ buyerSearchInput: Obj }
		})
	}

	buyerByZone(Obj1:AddressZoneInput,Obj2:PaginationOptions){
		return this.apollo.query({
			query:ADDRESS_BY_ZONE,
			variables:{ addressZoneInput: Obj1, paginationOptions: Obj2 }
		})
	}

	listBuyerAddress(buyerId: string) {
		return this.apollo.query<Buyer>({
			query: ADDRESS_BY_BUYER,
			variables: { buyerId: buyerId }
		})
	}

	listAddressTypes() {
		return this.apollo.query<[AddressTypes]>({
			query: ADDRESS_TYPES
		})
	}
	
	createBuyer(Obj: CreateBuyerInput) {
		return this.apollo.mutate<Buyer>({
			mutation: CREATE_BUYER,
			variables: { buyerInput: Obj }
		})
	}

	updateBuyer(Obj: UpdateBuyerDetailsInput, buyerId: String) {
		return this.apollo.mutate<Buyer>({
			mutation: UPDATE_BUYER,
			variables: { buyerInput: Obj, buyerId: buyerId },
			context: { useMultipart: true }
		})
	}
	
	followBuyer(Obj: FollowBuyerInput, buyerId: String) {
		return this.apollo.mutate<Buyer>({
			mutation: FOLLOW_BUYER,
			variables: { followBuyerInput: Obj, buyerId: buyerId }
		})
	}

	deleteBuyer(buyerId: string) {
		return this.apollo.mutate<successPayload>({
			mutation: DELETE_BUYER,
			variables: { buyerId: buyerId }
		})
	}

	resetPasswordBuyer(Obj: ResetUserPasswordInput) {
		return this.apollo.mutate<successPayload>({
			mutation: RESET_PASSWORD_BUYER,
			variables: { resetPasswordInput: Obj }
		})
	}

	resetEmailBuyer(Obj: ResetUserPasswordInput) {
		return this.apollo.mutate<successPayload>({
			mutation: RESET_EMAIL_REQUEST,
			variables: { resetEmailInput: Obj }
		})
	}

	reactivateBuyer(Obj: ActivationInput) {
		return this.apollo.mutate<successPayload>({
			mutation: REACTIVATE_BUYER,
			variables: { buyerInput: Obj }
		})
	}

	cityAreas(cityId: string) {
		return this.apollo.query<Area>({
			query: AREAS_BY_CITY,
			variables: { cityId: cityId }
		})
	}

	createBuyerAddress(Obj: CreateAddressInput) {
		return this.apollo.mutate<Address>({
			mutation: CREATE_ADDRESS,
			variables: { addressInput: Obj }
		})
	}

	updateBuyerAddress(Obj: UpdateAddressInput, addressId: string) {
		return this.apollo.mutate<Address>({
			mutation: UPDATE_ADDRESS,
			variables: { addressInput: Obj, addressId: addressId }
		})
	}

	deleteBuyerAddress(addressId: string) {
		return this.apollo.mutate<successPayload>({
			mutation: DELETE_ADDRESS,
			variables: { addressId: addressId }
		})
	}
}
