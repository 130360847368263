import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CreateEyeColorInput, EyeColors, PaginationOptions, successPayload, UpdateEyeColorInput } from '../graphql/types/types';
import { EYE_COLOR, EYE_COLORS } from '../graphql/queries/queries';
import { CREATE_EYE_COLOR, DELETE_EYE_COLOR, UPDATE_EYE_COLOR } from '../graphql/mutations/mutations';

@Injectable({
  providedIn: 'root'
})
export class EyeColorsService {

  constructor(private apollo: Apollo) { }

  public listAllEyeColors(Obj: PaginationOptions) {
    return this.apollo.query<[EyeColors]>({
      query: EYE_COLORS,
      variables: { paginationOptions: Obj }
    })
  }

  listEyeColors(eyeColorId:string) {
    return this.apollo.query<EyeColors>({
      query: EYE_COLOR,
      variables: { eyeColorId: eyeColorId }
    })
  }

  addEyeColors(Obj:CreateEyeColorInput){
    return this.apollo.mutate<EyeColors>({
      mutation:CREATE_EYE_COLOR,
      variables:{ createEyeColorInput: Obj },
      context:{ useMultipart: true }
    })
  }

  updateEyeColors(Obj:UpdateEyeColorInput,eyeColorId:string){
    return this.apollo.mutate<EyeColors>({
      mutation:UPDATE_EYE_COLOR,
      variables:{ updateEyeColorInput: Obj, eyeColorId:eyeColorId},
      context:{ useMultipart: true }
    })
  }

  public deleteEyeColors(eyeColorId:string){
    return this.apollo.mutate<successPayload>({
      mutation:DELETE_EYE_COLOR,
      variables:{ eyeColorId: eyeColorId }
    })
  }
}
