import { ADMIN, ADMIN_BY_TOKEN, PERMISSIONS } from './../graphql/queries/queries';
import { CREATE_ADMIN, UPDATE_ADMIN, DELETE_ADMIN, CHANGE_PASSSWORD_ADMIN } from './../graphql/mutations/mutations';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ADMINS } from '../graphql/queries/queries';
import { Admin, ChangePasswordInput, CreateAdminInput, PaginationOptions, successPayload, UpdateAdminDetailsInput, Permissions } from '../graphql/types/types';

@Injectable({
	providedIn: 'root'
})
export class AdminService {

	constructor(private apollo: Apollo) { }

	listAllAdmins(Obj: PaginationOptions) {
		return this.apollo.query<[Admin]>({
			query: ADMINS,
			variables: { paginationOptions: Obj }
		})
	}

	listAdmin(adminId: string) {
		return this.apollo.query<[Admin]>({
			query: ADMIN,
			variables: { adminId: adminId }
		})
	}

	createAdmin(Obj: CreateAdminInput) {
		return this.apollo.mutate<Admin>({
			mutation: CREATE_ADMIN,
			variables: { createAdminInput: Obj }
		})
	}

	updateAdmin(Obj: UpdateAdminDetailsInput, adminId: String) {
		return this.apollo.mutate<Admin>({
			mutation: UPDATE_ADMIN,
			variables: { updateAdminDetails: Obj, adminId: adminId }
		})
	}

	deleteAdmin(adminId: string) {
		return this.apollo.mutate<successPayload>({
			mutation: DELETE_ADMIN,
			variables: { adminId: adminId }
		})
	}

	listAdminInfo() {
		return this.apollo.query<Admin>({
			query: ADMIN_BY_TOKEN
		})
	}

	changePasswordAdmin(obj: ChangePasswordInput, adminId: string) {
		return this.apollo.mutate<successPayload>({
			mutation: CHANGE_PASSSWORD_ADMIN,
			variables: { changePasswordInput: obj, adminId: adminId }
		})
	}

	listPermissions() {
		return this.apollo.query<Permissions>({
			query: PERMISSIONS
		})
	}

}
