import { CreateCityInput, successPayload, UpdateCityInput } from './../graphql/types/types';
import { CREATE_CITY, UPDATE_CITY, DELETE_CITY } from './../graphql/mutations/mutations';
import { CITIES, CITY } from './../graphql/queries/queries';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { City, PaginationOptions } from '../graphql/types/types';

@Injectable({
  providedIn: 'root'
})
export class CityService {

  constructor(private apollo: Apollo) { }

  listAllCities(Obj: PaginationOptions) {
    return this.apollo.query<[City]>({
      query: CITIES,
      variables: { paginationOptions: Obj }
    })
  }

  listCity(cityId:string){
    return this.apollo.query<[City]>({
      query: CITY,
      variables: { cityId: cityId }
    })
  }
  addCity(Obj:CreateCityInput){
    return this.apollo.mutate<City>({
      mutation:CREATE_CITY,
      variables:{ cityInput: Obj }
    })
  }

  updateCity(Obj:UpdateCityInput,cityId:string){
    return this.apollo.mutate<City>({
      mutation:UPDATE_CITY,
      variables:{ cityInput: Obj, cityId: cityId }
    })
  }

  deleteCity(cityId:string){
    return this.apollo.mutate<successPayload>({
      mutation:DELETE_CITY,
      variables:{ cityId: cityId }
    })
  }
  
}
