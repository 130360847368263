import { CREATE_ATTRIBUTE_VALUE, UPDATE_ATTRIBUTE_VALUE, DELETE_ATTRIBUTE_VALUE } from './../graphql/mutations/mutations';
import { ATTRIBUTE_VALUE } from './../graphql/queries/queries';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ATTRIBUTES_VALUES } from '../graphql/queries/queries';
import { AttributeValue, CreateAttributeValueInput, PaginationOptions, successPayload, UpdateAttributeValueInput } from '../graphql/types/types';

@Injectable({
  providedIn: 'root'
})
export class AttributeValueService {

  constructor(private apollo: Apollo) { }

  listAllAttributesValues(Obj: PaginationOptions) {
    return this.apollo.query<[AttributeValue]>({
      query: ATTRIBUTES_VALUES,
      variables: { paginationOptions: Obj }
    })
  }


  listAttributeValue(attributeValueId: string) {
    return this.apollo.query<AttributeValue>({
      query: ATTRIBUTE_VALUE,
      variables: { attributeValueId: attributeValueId }
    })
  }

  addAttributeValue(Obj: CreateAttributeValueInput) {
    return this.apollo.mutate<AttributeValue>({
      mutation: CREATE_ATTRIBUTE_VALUE,
      variables: { attributeValueInput: Obj }
    })
  }

  updateAttributeValue(Obj: UpdateAttributeValueInput, attributeValueId: string) {
    return this.apollo.mutate<AttributeValue>({
      mutation: UPDATE_ATTRIBUTE_VALUE,
      variables: { attributeValueInput: Obj, attributeValueId: attributeValueId }
    })
  }

  deleteAttributeValue(attributeValueId: string) {
    return this.apollo.mutate<successPayload>({
      mutation: DELETE_ATTRIBUTE_VALUE,
      variables: { attributeValueId: attributeValueId }
    })
  }

}
