import { CATEGORIES_FOR_SUBCATEGORY, CATEGORY } from './../graphql/queries/queries';
import { UPDATE_CATEGORY, DELETE_CATEGORY } from './../graphql/mutations/mutations';
import { Category, CreateCategoryInput, UpdateCategoryInput, successPayload } from './../graphql/types/types';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { PaginationOptions } from '../graphql/types/types';
import { CATEGORIES } from '../graphql/queries/queries';
import { CREATE_CATEGORY } from '../graphql/mutations/mutations';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private apollo: Apollo) { }

  listAllCategories(Obj: PaginationOptions) {
    return this.apollo.query<[Category]>({
      query: CATEGORIES,
      variables: { paginationOptions: Obj }
    })
  }

  listCategory(categoryId:string) {
    return this.apollo.query<Category>({
      query: CATEGORY,
      variables: { categoryId: categoryId }
    })
  }

  listCategoriesForSubCategory(Obj: PaginationOptions){
    return this.apollo.query<[Category]>({
      query:CATEGORIES_FOR_SUBCATEGORY,
      variables: { paginationOptions: Obj }
    })
  }

  addCategory(Obj:CreateCategoryInput){
    return this.apollo.mutate<Category>({
      mutation:CREATE_CATEGORY,
      variables:{ createCategoryInput: Obj },
      context:{ useMultipart: true }
    })
  }

  updateCategory(Obj:UpdateCategoryInput,categoryId:string){
    return this.apollo.mutate<Category>({
      mutation:UPDATE_CATEGORY,
      variables:{ updateCategoryInput: Obj, categoryId:categoryId},
      context:{ useMultipart: true }
    })
  }

  deleteCategory(categoryId:string){
    return this.apollo.mutate<successPayload>({
      mutation:DELETE_CATEGORY,
      variables:{ categoryId: categoryId }
    })
  }
}
