import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CREATE_HEIGHT_RANGE, DELETE_HEIGHT_RANGE, UPDATE_HEIGHT_RANGE } from '../graphql/mutations/mutations';
import { HEIGHT_RANGE, HEIGHT_RANGES } from '../graphql/queries/queries';
import { CreateHeightRangeInput, HeightRanges, PaginationOptions, successPayload, UpdateHeightRangeInput } from '../graphql/types/types';

@Injectable({
  providedIn: 'root'
})
export class HeightRangesService {

  constructor(private apollo: Apollo) { }

  public listAllHeightRange(Obj: PaginationOptions) {
    return this.apollo.query<[HeightRanges]>({
      query: HEIGHT_RANGES,
      variables: { paginationOptions: Obj }
    })
  }

  listHeightRange(heightRangeId:string) {
    return this.apollo.query<HeightRanges>({
      query: HEIGHT_RANGE,
      variables: { heightRangeId: heightRangeId }
    })
  }

  public addHeightRange(Obj:CreateHeightRangeInput){
    return this.apollo.mutate<HeightRanges>({
      mutation:CREATE_HEIGHT_RANGE,
      variables:{ createHeightRangeInput: Obj },
      context:{ useMultipart: true }
    })
  }

  updateHeightRange(Obj:UpdateHeightRangeInput,heightRangeId:string){
    return this.apollo.mutate<HeightRanges>({
      mutation:UPDATE_HEIGHT_RANGE,
      variables:{ updateHeightRangeInput: Obj, heightRangeId:heightRangeId},
      context:{ useMultipart: true }
    })
  }

  public deleteHeightRange(heightRangeId:string){
    return this.apollo.mutate<successPayload>({
      mutation:DELETE_HEIGHT_RANGE,
      variables:{ heightRangeId: heightRangeId }
    })
  }
}
