import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CREATE_FAVOURITE_COLOR, DELETE_FAVOURITE_COLOR, UPDATE_FAVOURITE_COLOR } from '../graphql/mutations/mutations';
import { FAVOURITE_COLOR, FAVOURITE_COLORS } from '../graphql/queries/queries';
import { CreateFavouriteColorInput, FavouriteColor, PaginationOptions, successPayload, UpdateFavouriteColorInput } from '../graphql/types/types';

@Injectable({
  providedIn: 'root'
})
export class FavouriteColorService {

  constructor(private apollo: Apollo) { }

  listAllFavouriteColor(Obj: PaginationOptions) {
    return this.apollo.query<[FavouriteColor]>({
      query: FAVOURITE_COLORS,
      variables: { paginationOptions: Obj }
    })
  }

  listFavouriteColor(favouriteColorId:string) {
    return this.apollo.query<FavouriteColor>({
      query: FAVOURITE_COLOR,
      variables: { favouriteColorId: favouriteColorId }
    })
  }

  addFavouriteColor(Obj:CreateFavouriteColorInput){
    return this.apollo.mutate<FavouriteColor>({
      mutation:CREATE_FAVOURITE_COLOR,
      variables:{ createFavouriteColorInput: Obj },
      context:{ useMultipart: true }
    })
  }

  updateFavouriteColor(Obj:UpdateFavouriteColorInput,favouriteColorId:string){
    return this.apollo.mutate<FavouriteColor>({
      mutation:UPDATE_FAVOURITE_COLOR,
      variables:{ updateFavouriteColorInput: Obj, favouriteColorId:favouriteColorId},
      context:{ useMultipart: true }
    })
  }

  deleteFavouriteColor(favouriteColorId:string){
    return this.apollo.mutate<successPayload>({
      mutation:DELETE_FAVOURITE_COLOR,
      variables:{ favouriteColorId: favouriteColorId }
    })
  }
}
