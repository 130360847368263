import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CREATE_SHOES_RANGE, DELETE_SHOES_RANGE, UPDATE_SHOES_RANGE } from '../graphql/mutations/mutations';
import { SHOES_RANGE, SHOES_RANGES } from '../graphql/queries/queries';
import { CreateShoesRange, PaginationOptions, ShoesRanges, successPayload, UpdateShoesRangeInput } from '../graphql/types/types';

@Injectable({
  providedIn: 'root'
})
export class ShoesRangesService {

  constructor(private apollo: Apollo) { }

  public listAllShoesRange(Obj: PaginationOptions) {
    return this.apollo.query<[ShoesRanges]>({
      query: SHOES_RANGES,
      variables: { paginationOptions: Obj }
    })
  }

  listShoesRange(shoesRangeId:string) {
    return this.apollo.query<ShoesRanges>({
      query: SHOES_RANGE,
      variables: { shoesRangeId: shoesRangeId }
    })
  }

  addShoesRange(Obj:CreateShoesRange){
    return this.apollo.mutate<ShoesRanges>({
      mutation:CREATE_SHOES_RANGE,
      variables:{ createShoesRangeInput: Obj },
      context:{ useMultipart: true }
    })
  }

  updateShoesRange(Obj:UpdateShoesRangeInput,shoesRangeId:string){
    return this.apollo.mutate<ShoesRanges>({
      mutation:UPDATE_SHOES_RANGE,
      variables:{ updateShoesRangeInput: Obj, shoesRangeId:shoesRangeId},
      context:{ useMultipart: true }
    })
  }

  public deleteShoesRange(shoesRangeId:string){
    return this.apollo.mutate<successPayload>({
      mutation:DELETE_SHOES_RANGE,
      variables:{ shoesRangeId: shoesRangeId }
    })
  }
}
