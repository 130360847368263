import { CREATE_TAG, UPDATE_TAG, DELETE_TAG } from './../graphql/mutations/mutations';
import { CreateTagInput, successPayload, Tag, UpdateTagInput } from './../graphql/types/types';
import { TAG, TAGS } from './../graphql/queries/queries';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { PaginationOptions } from '../graphql/types/types';

@Injectable({
	providedIn: 'root'
})
export class TagService {

	constructor(private apollo: Apollo) { }

	listAllTags(Obj: PaginationOptions) {
		return this.apollo.query<[Tag]>({
		  query: TAGS,
		  variables: { paginationOptions: Obj }
		})
	  }
	
	
	  listTag(tagId: string) {
		return this.apollo.query<Tag>({
		  query: TAG,
		  variables: { tagId: tagId }
		})
	  }
	
	  addTag(Obj:CreateTagInput){
		return this.apollo.mutate<Tag>({
		  mutation:CREATE_TAG,
		  variables:{ tagInput: Obj }
		})
	  }
	
	  updateTag(Obj:UpdateTagInput,tagId:string){
		return this.apollo.mutate<Tag>({
		  mutation:UPDATE_TAG,
		  variables:{ tagInput: Obj, tagId: tagId }
		})
	  }
	
	  deleteTag(tagId:string){
		return this.apollo.mutate<successPayload>({
		  mutation:DELETE_TAG,
		  variables:{ tagId: tagId }
		})
	  }

}
