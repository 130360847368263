import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CREATE_HEAR_COLOR, DELETE_HEAR_COLOR, UPDATE_HEAR_COLOR } from '../graphql/mutations/mutations';
import { HAIR_COLOR, HAIR_COLORS } from '../graphql/queries/queries';
import { CreateHairColorInput, HairColors, PaginationOptions, successPayload, UpdateHairColorInput } from '../graphql/types/types';

@Injectable({
  providedIn: 'root'
})
export class HearColorService {

  constructor(private apollo: Apollo) { }

  public listAllHearColor(Obj: PaginationOptions) {
    return this.apollo.query<[HairColors]>({
      query: HAIR_COLORS,
      variables: { paginationOptions: Obj }
    })
  }

  listHearColor(hairColorId:string) {
    return this.apollo.query<HairColors>({
      query: HAIR_COLOR,
      variables: { hairColorId: hairColorId }
    })
  }

  public addHearColor(Obj:CreateHairColorInput){
    return this.apollo.mutate<HairColors>({
      mutation:CREATE_HEAR_COLOR,
      variables:{ createHairColorInput: Obj },
      context:{ useMultipart: true }
    })
  }

  updateHearColor(Obj:UpdateHairColorInput,hairColorId:string){
    return this.apollo.mutate<HairColors>({
      mutation:UPDATE_HEAR_COLOR,
      variables:{ updateHairColorInput: Obj, hairColorId:hairColorId},
      context:{ useMultipart: true }
    })
  }

  public deleteHearColor(hairColorId:string){
    return this.apollo.mutate<successPayload>({
      mutation:DELETE_HEAR_COLOR,
      variables:{ hairColorId: hairColorId }
    })
  }
}
