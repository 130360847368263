import { BehaviorSubject } from 'rxjs';
import { PRODUCT, CATEGORY_PRODUCT, SUB_CATEGORY_PRODUCT, CROSS_SELLING_PRODUCT, CATEGORIES_PRODUCT, SUB_CATEGORIES_PRODUCT, PRODUCT_SEARCH, PRODUCTS_COUNT } from './../graphql/queries/queries';
import { Product, Category, CreateProductImageInput, SpecInput, CountPayload } from './../graphql/types/types';
import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { PaginationOptions } from '../graphql/types/types';
import { PRODUCTS } from '../graphql/queries/queries';
import { CREATE_PRODUCT, CREATE_PRODUCT_IMAGE, CREATE_PRODUCT_WITH_VARIANTS, DELETE_PRODUCT, DELETE_PRODUCT_IMAGE, UPDATE_PRODUCT } from '../graphql/mutations/mutations';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  productImages: BehaviorSubject<number[]> = new BehaviorSubject([]);
  constructor(private apollo: Apollo) { }

  listAllProducts(Obj: PaginationOptions) {
    return this.apollo.query<[Product]>({
      query: PRODUCTS,
      variables: { paginationOptions: Obj }
    })
  }

  productsCount(){
    return this.apollo.query<CountPayload>({
      query: PRODUCTS_COUNT
    })
  }

  addProduct(obj) {
    return this.apollo.mutate<Product>({
      mutation: CREATE_PRODUCT,
      variables: { createProductInput: obj },
      context: { useMultipart: true }
    })
  }
  
  createProduct(obj) {
    return this.apollo.mutate<Product>({
      mutation: CREATE_PRODUCT,
      variables: { createProductInput: obj },
      context: { useMultipart: true }
    })
  }
  
  productSearch(name){
    return this.apollo.query<Product>({
      query: PRODUCT_SEARCH,
      variables: { name: name }
    })
  }

  listProduct(productId: string) {
    return this.apollo.query<Product>({
      query: PRODUCT,
      variables: { productId: productId }
    })
  }

  listProductCategory(categoryId: string) {
    return this.apollo.query<Product>({
      query: CATEGORY_PRODUCT,
      variables: { categoryId: categoryId }
    })
  }

  listProductCategories(Obj: PaginationOptions) {
    return this.apollo.query<Category>({
      query: CATEGORIES_PRODUCT,
      variables: { paginationOptions: Obj }
    })
  }

  listProductSubCategories(Obj: PaginationOptions) {
    return this.apollo.query<Category>({
      query: SUB_CATEGORIES_PRODUCT,
      variables: { paginationOptions: Obj }
    })
  }

  listProductSubCategory(subCategoryId: string) {
    return this.apollo.query<Product>({
      query: SUB_CATEGORY_PRODUCT,
      variables: { subCategoryId: subCategoryId }
    })
  }


  listCrossSelling(Obj) {
    return this.apollo.query<[Product]>({
      query: CROSS_SELLING_PRODUCT,
      variables: { paginationOptions: Obj }
    })
  }

  updateProduct(obj, productId) {
    return this.apollo.mutate<Product>({
      mutation: UPDATE_PRODUCT,
      variables: { updateProductInput: obj, productId: productId }
    })
  }

  deleteProduct(productId: string) {
    return this.apollo.mutate<Product>({
      mutation: DELETE_PRODUCT,
      variables: { productId: productId }
    })
  }

  deleteProductImage(imageName: string, productId: string) {
    return this.apollo.mutate({
      mutation: DELETE_PRODUCT_IMAGE,
      variables: { imageName: imageName, productId: productId }
    })
  }

  addProductImage(Obj: CreateProductImageInput, productId: string) {
    return this.apollo.mutate({
      mutation: CREATE_PRODUCT_IMAGE,
      variables: { createProductImageInput: Obj, productId: productId },
      context: { useMultipart: true }
    })
  }
}
