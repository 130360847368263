import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CreateWearMood, PaginationOptions, successPayload, UpdateWearMoodInput, WearMoods } from 'app/@resources/graphql/types/types';
import { CREATE_WEAR_MOOD, DELETE_WEAR_MOOD, UPDATE_WEAR_MOOD } from '../graphql/mutations/mutations';
import { WEAR_MOODS, WEAR_MOOD_BY_ID } from '../graphql/queries/queries';

@Injectable({
  providedIn: 'root'
})
export class WearMoodsService {

  constructor(private apollo: Apollo) { }

  public listAllWearMoods(Obj: PaginationOptions) {
    return this.apollo.query<[WearMoods]>({
      query: WEAR_MOODS,
      variables: { paginationOptions: Obj }
    })
  }

  listWearMood(wearMoodId:string) {
    return this.apollo.query<WearMoods>({
      query: WEAR_MOOD_BY_ID,
      variables: { wearMoodId: wearMoodId }
    })
  }

  public addWearMood(Obj: CreateWearMood) {
    return this.apollo.mutate<WearMoods>({
      mutation: CREATE_WEAR_MOOD,
      variables: { createWearMoodInput : Obj },
      context: { useMultipart: true }
    })
  }

  updateWearMood(Obj: UpdateWearMoodInput, wearMoodId:string){
    return this.apollo.mutate<WearMoods>({
      mutation: UPDATE_WEAR_MOOD,
      variables: { updateWearMoodInput: Obj, wearMoodId: wearMoodId },
      context: { useMultipart: true }
    })
  }

  public deleteWearMood(wearMoodId:string) {
    return this.apollo.mutate<successPayload>({
      mutation: DELETE_WEAR_MOOD,
      variables: { wearMoodId:wearMoodId }
    })
  }

}
