import { CREATE_VARIANT } from './../graphql/mutations/mutations';
import { PaginationOptions, Variant } from './../graphql/types/types';
import { VARIANT, VARIANTS } from './../graphql/queries/queries';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class VaraintService {


  constructor(private apollo: Apollo) { }

  listAllVariantss(Obj: PaginationOptions) {
    return this.apollo.query<[Variant]>({
      query: VARIANTS,
      variables: { paginationOptions: Obj }
    })
  }



  listVariant(variantId: string) {
    return this.apollo.query<Variant>({
      query: VARIANT,
      variables: { variantId: variantId }
    })
  }

  addVariant(obj) {
    return this.apollo.mutate<Variant>({
      mutation: CREATE_VARIANT,
      variables: { createVariantInput: obj },
      context: { useMultipart: true }
    })
  }
  
}
