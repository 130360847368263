import { SUB_CATEGORIES_BY_CATEGORY_ID } from './../graphql/queries/queries';
import { subCategory, CreateSubCategoryInput, successPayload, UpdateSubCategoryInput } from './../graphql/types/types';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { PaginationOptions } from '../graphql/types/types';
import { SUB_CATEGORIES, SUB_CATEGORY } from '../graphql/queries/queries';
import { CREATE_SUB_CATEGORY, DELETE_SUB_CATEGORY, UPDATE_SUB_CATEGORY } from '../graphql/mutations/mutations';

@Injectable({
  providedIn: 'root'
})
export class SubCategoryService {

  constructor(private apollo: Apollo) { }

  listAllSubCategories(Obj: PaginationOptions) {
    return this.apollo.query<[subCategory]>({
      query: SUB_CATEGORIES,
      variables: { paginationOptions: Obj }
    })
  }

  listSubCategoriesByCategoryId(categoryId:string) {
    return this.apollo.query<[subCategory]>({
      query: SUB_CATEGORIES_BY_CATEGORY_ID,
      variables: { categoryId: categoryId }
    })
  }

  listSubCategory(subCategoryId: string) {
    return this.apollo.query<subCategory>({
      query: SUB_CATEGORY,
      variables: { subCategoryId: subCategoryId }
    })
  }

  addSubCategory(Obj:CreateSubCategoryInput){
    return this.apollo.mutate<subCategory>({
      mutation:CREATE_SUB_CATEGORY,
      variables:{ subCategoryInput: Obj },
      context:{ useMultipart: true }
    })
  }

  updateSubCategory(Obj:UpdateSubCategoryInput,subCategoryId:string){
    return this.apollo.mutate<subCategory>({
      mutation:UPDATE_SUB_CATEGORY,
      variables:{ subCategoryInput: Obj, subCategoryId: subCategoryId },
      context:{ useMultipart: true }
    })
  }

  deleteSubCategory(subCategoryId:string){
    return this.apollo.mutate<successPayload>({
      mutation:DELETE_SUB_CATEGORY,
      variables:{ subCategoryId: subCategoryId }
    })
  }
}
