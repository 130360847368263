import { CREATE_ORDER } from './../graphql/mutations/mutations';
import { ORDER, ORDERS_COUNT, ORDER_BY_STATUS, ORDER_ORDER_STATUS } from './../graphql/queries/queries';
import { Order, OrderStatus, UpdateOrderInput, CreateOrderInput, OrderStatusInput, CountPayload, Returns } from './../graphql/types/types';
import { PaginationOptions } from 'app/@resources/graphql/types/types';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ORDERS, RETURNS } from '../graphql/queries/queries';
import { UPDATE_ORDER } from '../graphql/mutations/mutations';

@Injectable({
	providedIn: 'root'
})
export class OrderService {


	constructor(private apollo: Apollo) { }

	listAllOrders(Obj: PaginationOptions) {
		return this.apollo.query<[Order]>({
			query: ORDERS,
			variables: { paginationOptions: Obj }
		})
	}

	listAllReturns(Obj: PaginationOptions) {
		return this.apollo.query<[Returns]>({
			query: RETURNS,
			variables: { paginationOptions: Obj }
		})
	}

	listOrder(orderId:string) {
		return this.apollo.query<Order>({
			query: ORDER,
			variables: { orderId: orderId }
		})
	}

	ordersCount(){
		return this.apollo.query<CountPayload>({
			query: ORDERS_COUNT
		})
		
	}
	OrderByStatus(Obj1:OrderStatusInput,Obj2: PaginationOptions){
		return this.apollo.query<Order>({
			query: ORDER_BY_STATUS,
			variables: { orderStatusInput: Obj1, paginationOptions: Obj2 }
		})
	}

	updateOrder(Obj:UpdateOrderInput) {
		return this.apollo.mutate<Order>({
			mutation: UPDATE_ORDER,
			variables: { orderInput: Obj }
		  })
	}

	listOrderStatuses(){
		return this.apollo.query<OrderStatus>({
			query: ORDER_ORDER_STATUS
		})
	}

	createOrder(Obj:CreateOrderInput){
		return this.apollo.mutate<Order>({
			mutation: CREATE_ORDER,
			variables: { createOrderInput: Obj }
		  })
	}
}
